import React from 'react'
import { Link } from 'gatsby'
import { IntlContext } from 'react-intl'
//
import { SiteDataContext } from '~components/layout/wrappers'

const SITE_URL = process.env.SITE_URL || ''

export const NavLink = ({ to, children, ...rest }) => {
  if (!to) {
    return <>{children}</>
  }

  const intl = React.useContext(IntlContext)
  const siteData = React.useContext(SiteDataContext)

  const projectCode = siteData?.group?.fieldGroupProjectCode || ''

  let path = to

  const homepage = path === SITE_URL || path === '/' ? path : null
  path = to.startsWith(SITE_URL) ? to.replace(SITE_URL, '') : to
  path = path.replace(`/${projectCode}`, '')

  if (homepage || path.startsWith('/')) {
    return (
      <Link to={homepage ? `/${intl?.locale || ''}` : path.replace(`/${projectCode}`, '')} {...rest}>
        {children}
      </Link>
    )
  }

  const props = {}

  if (to === '#') {
    if (!props.onClick) {
      props.onClick = (e) => {
        e.preventDefault()
        return false
      }
    }
    return (
      <a href={to} {...props} {...rest}>
        {children}
      </a>
    )
  }

  if (!SITE_URL || !path.startsWith(SITE_URL)) {
    props.target = '_blank'
    props.rel = 'noreferrer nofollow'
  }

  if(path.startsWith('#')) {
    props.target = ''
    props.rel = ''
  }

  if (rest?.activeClassName) {
    props.className = props?.className ? `${props.className} ${rest.activeClassName}` : rest.activeClassName
    delete rest.activeClassName
  }

  return (
    <a href={path} {...props} {...rest}>
      {children}
    </a>
  )
}

export default NavLink
