import React, { useState, useEffect } from 'react'

import { IntlContext } from 'react-intl'
import tw, { css, styled } from 'twin.macro'

//
import { useComponentVisible } from '~utils/dropdown'
import NavLink from '~utils/navlink'
import logoUrl from '~images/be-logo.png'

const SelectChevron = styled.span`
  ${css`
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 48 48' fill='%23323341' aria-hidden='true'%3E%3Cpolygon fill='%23323341' points='0,12.438 48,12.438 24,35.562 ' /%3E%3C/svg%3E");
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
  `}
  ${tw`absolute inset-y-0 right-2 flex items-center pointer-events-none pt-3 pr-3 text-black`}
`

const StyledInfo = styled.div`
  ${tw`text-c-fed-header-text font-normal`}
  a {
    ${tw`no-underline text-c-fed-header-text font-normal`}
  }
`
const LanguagesDesktop = ({ setOpen, setActive, active, langs }) => (
  <nav key={`list-${active}`} className="text-base hidden lg:block">
    {active
      ? Object.keys(langs).map((code) => (
          <span key={`lang-${code}`}>
            <NavLink
              className="uppercase no-underline text-sm text-c-fed-header-text font-thin hover:text-c-fed-header-textLight hover:bg-c-fed-header-hoverBg px-3 py-2 mr-2"
              css={code === active ? tw`bg-c-fed-header-bg text-c-fed-header-textLight` : null}
              to={langs?.[code]?.url}
              onClick={(e) => {
                setActive(langs?.[code]?.label)
                setOpen(false)
                if (langs?.[code]?.onClick) {
                  // eslint-disable-next-line no-unused-expressions
                  langs?.[code]?.onClick(e)
                }
              }}
            >
              {langs?.[code]?.label}
            </NavLink>
          </span>
        ))
      : null}
  </nav>
)
const LanguagesMobile = ({ setOpen, setActive, open, active, langs }) => (
  <div className="block lg:hidden">
    <div className="relative inline-block text-left w-13">
      <div>
        <button
          className="uppercase no-underline text-sm text-c-fed-header-buttonText hover:text-c-fed-header-buttonText pl-2 pr-8 py-1 bg-c-fed-header-buttonBg inline-flex justify-center focus:outline-none"
          type="button"
          id="options-menu"
          aria-haspopup="true"
          aria-expanded="true"
          css={!open}
          onClick={(e) => setOpen(!open)}
        >
          {active}
          <SelectChevron />
        </button>
      </div>
      {open && (
        <div className="origin-top-left absolute text-left left-0 w-full bg-c-fed-header-bg z-20">
          <div className="py-1" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
            {Object.keys(langs)
              .filter((code) => code !== active)
              .map((code) => (
                <NavLink
                  key={`lang-${code}`}
                  className="block uppercase no-underline text-sm font-thin bg-c-fed-header-bgLight text-c-fed-header-text hover:text-c-fed-header-textLight hover:bg-c-fed-header-bg px-2 py-2"
                  css={code === active ? tw`bg-c-fed-header-bg` : null}
                  to={langs?.[code]?.url}
                  onClick={(e) => {
                    setActive(langs?.[code]?.label)
                    setOpen(false)
                    if (langs?.[code]?.onClick) {
                      // eslint-disable-next-line no-unused-expressions
                      langs?.[code]?.onClick(e)
                    }
                  }}
                >
                  {langs?.[code]?.label}
                </NavLink>
              ))}
          </div>
        </div>
      )}
    </div>
  </div>
)

export const FederalHeader = ({ text, language, langs }) => {
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false)
  const intl = React.useContext(IntlContext)
  const locale = intl?.locale
  const [active, setActive] = useState(language)

  useEffect(() => {
    if (!language) {
      setActive(locale)
    }
  }, [locale, language])

  return (
    <div className="bg-c-fed-header-bgLight w-full flex relative">
      <div ref={ref} className="container max-w-7xl flex flex-row justify-between mx-auto px-6 md:px-10 py-2 md:py-5">
        <div className="block relative w-full">
          {(langs && Object.keys(langs).length > 1)  ? (
            <>
              <LanguagesDesktop {...{ setOpen: setIsComponentVisible, setActive, active, langs }} />
              <LanguagesMobile
                {...{ setOpen: setIsComponentVisible, open: isComponentVisible, setActive, active, langs }}
              />
            </>
          ) : null}
        </div>

        <div className="container flex flex-row justify-end">
          <StyledInfo className="text-sm mr-8 pt-1 hidden lg:block">{text}</StyledInfo>

          <div>
            <img
              typeof="foaf:Image"
              src={logoUrl}
              width="32"
              height="23"
              alt="Logo of the Belgian Federal Authorities"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default FederalHeader
