import React from 'react'
import { navigate } from 'gatsby'
import { FormattedMessage } from 'react-intl'
//
import DefaultHeader, { FederalHeader } from '~storybook/headers'
import { SiteDataContext } from '~components/layout/wrappers'
import SocialLinks from '~storybook/social-links'
import NavLink from '~utils/navlink'

const Header = ({ navigation, secondary, language, disableLangSwitcher, splashHeader = false }) => {
  const data = React.useContext(SiteDataContext)
  const { langSlugs, group } = data
  const {
    fieldLogo,
    fieldSiteName,
    fieldSearchStatus,
    fieldLogoAlignment,
    fieldHeaderSocialMediaLinks,
    fieldSocialmediaFacebook,
    fieldSocialmediaInstagram,
    fieldSocialmediaLinkedin,
    fieldSocialmediaTwitter,
    fieldHideLogoSitename,
    fieldDisableMainNavigation
  } = group
  const logo = fieldLogo?.entity?.thumbnail || {}
  const langs = {}

  Object.keys(langSlugs).forEach((key) => {
    langs[key] = {
      url: langSlugs[key],
      label: key
    }
  })

  if (fieldLogoAlignment === 'center' || splashHeader) {
    logo.center = true
  }

  const handleSearch = (str) => {
    navigate(`/${language}/search?q=${str}`)
  }

  let SocialLinksComp = null

  if (fieldHeaderSocialMediaLinks) {
    const socialLinks = fieldHeaderSocialMediaLinks && {}

    if (fieldSocialmediaTwitter?.url?.path) {
      socialLinks.twitter = { url: fieldSocialmediaTwitter?.url?.path, label: 'Twitter' }
    }
    if (fieldSocialmediaFacebook?.url?.path) {
      socialLinks.facebook = { url: fieldSocialmediaFacebook?.url?.path, label: 'Facebook' }
    }
    if (fieldSocialmediaLinkedin?.url?.path) {
      socialLinks.linkedin = { url: fieldSocialmediaLinkedin?.url?.path, label: 'Linkedin' }
    }
    if (fieldSocialmediaInstagram?.url?.path) {
      socialLinks.instagram = { url: fieldSocialmediaInstagram?.url?.path, label: 'Instagram' }
    }

    SocialLinksComp = <SocialLinks header links={{ ...socialLinks }} />
  }

  return (
    <div data-search-ignore="true">
      <FederalHeader
        text={
          <>
            <FormattedMessage id="header.message" />{' '}
            <NavLink className="hover:underline focus:underline" to="https://www.belgium.be">
              belgium.be
            </NavLink>
          </>
        }
        language={language}
        langs={disableLangSwitcher ? [] : langs}
      />
      <DefaultHeader
        navigation={splashHeader ? [] : navigation}
        extra={splashHeader ? [] : secondary}
        logo={splashHeader ? null : logo?.url && logo}
        title={logo?.url ? null : fieldSiteName}
        search={splashHeader ? false : fieldSearchStatus}
        socialLinks={SocialLinksComp}
        onSearch={handleSearch}
        fieldHideLogoSitename={fieldHideLogoSitename}
        fieldDisableMainNavigation={fieldDisableMainNavigation}
        
      />
    </div>
  )
}

export default Header
