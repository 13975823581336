import React from 'react'
import tw, { theme, styled } from 'twin.macro'
import { Heading } from '~storybook/blocks/typography/index'
//
import Icons from '~storybook/icons'
import NavLink from '~utils/navlink'

const AnnouncementWrapper = styled.section`
  ${({ danger }) => (danger ? tw`bg-c-announcement-wrapper-important` : tw`bg-c-announcement-wrapper-generic`)}
  ${tw`flex relative w-full text-white`}

  svg {
    width: 32px;
    height: 32px;
    * {
      fill: ${({ danger }) =>
        danger
          ? theme`colors['c-announcement-wrapper'].dangerLight`
          : theme`colors['c-announcement-wrapper'].dangerDark`};
    }
  }

  .close svg {
    width: 48px;
    height: 48px;

    @media (max-width: ${`${parseInt(`${theme`screens.md`}`.replace('px', ''), 10) - 1}px`}) {
      width: 32px !important;
      height: 32px !important;
    }

    * {
      fill: ${theme`colors['c-announcement-wrapper'].closeBtn`};
    }
  }

  a {
    ${tw`text-white hover:text-white`}
  }
`

export const Announcement = ({
  danger = true,
  text = '',
  title = '',
  link = false,
  onDismiss = () => {
    /* noop */
  }
}) => {
  return (
    <AnnouncementWrapper danger={danger} data-search-ignore="true">
      <div className="relative container max-w-7xl content-center justify-between mx-auto px-6 md:px-10 mb-6 mt-6 lg:mb-6 lg:my-6">
        <div className="w-full flex flex-row justify-between items-start">
          <div className="flex flex-row justify-start">
            <span className="mr-4 md:mr-6">
              <Icons.InfoFilled />
            </span>
            <div>
              <Heading className="m-0 p-0" level="h5">
                {title}
              </Heading>
              <div className="m-0 p-0 float-left mr-2">{text}</div>
              {link && link?.label && link?.url && (
                <NavLink to={link?.url} className="float-left">
                  {link?.label}
                </NavLink>
              )}
            </div>
          </div>
          <div className="flex flex-col items-end ml-4 md:ml-6">
            <button
              type="button"
              className="close outline-none focus:outline-none text-white hover:text-white"
              onClick={() => {
                onDismiss()
              }}
            >
              <Icons.Close style={{ width: '48px', height: '48px' }} />
            </button>
          </div>
        </div>
      </div>
    </AnnouncementWrapper>
  )
}

export default Announcement
